<template>
  <div>
    <vxe-toolbar class="px-3" :refresh="{ query: getFirmList }">
      <template v-slot:buttons>
        <vxe-input
          v-model="filterName"
          type="search"
          placeholder="全文搜索"
          class="mr-1"
        ></vxe-input>
        <vxe-button
          class="px-1 py-0 ml-2"
          status="my-primary"
          @click="addFirm()"
          size="mini"
          content="新建组合"
        ></vxe-button>
      </template>
    </vxe-toolbar>

    <vxe-table
      id="coreTable"
      row-id="id"
      ref="coreTable"
      :data="list"
      :border="false"
      resizable
      show-overflow
      show-header-overflow
      highlight-hover-row
      align="center"
      :loading="coreTableLoading"
      :column-config="{ resizable: true }"
      :height="tableHeight"
      size="small"
      :stripe="true"
      :sort-config="{ trigger: 'cell', multiple: true }"
      :scroll-y="{ mode: 'wheel', gt: 50, oSize: 30 }"
      :empty-render="{ name: 'NotData' }"
      :filter-config="{ showIcon: false }"
      :export-config="{}"
    >
      <vxe-column fixed="left" min-width="70" field="code" title="组合名称">
        <template #default="{ row }">
          <div class="d-flex justify-start" @click="openFirm(row.id)">
            <a
              href="javascript:;"
              class="text-xs font-weight-normal text-typo"
              >{{ row.name ? row.name : "-" }}</a
            >
          </div>
        </template>
      </vxe-column>

      <vxe-column width="140" field="created_at" title="创建日期" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.created_at }}
          </div>
        </template>
      </vxe-column>

      <vxe-column width="140" field="created_at" title="更新日期" sortable>
        <template #default="{ row }">
          <div class="d-flex justify-start">
            {{ row.updated_at }}
          </div>
        </template>
      </vxe-column>

      <vxe-column
        title="操作"
        type="seq"
        width="120"
        :resizable="false"
        show-overflow
      >
        <template #default="{ row }">
          <div>
            <a
              href="javascript:;"
              @click="deleteFirm(row.id)"
              class="text-xs font-weight-normal text-typo"
              >删除</a
            >
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import FirmAdd from "@/components/Firm/FirmAdd.vue";
import Firm from "@/api/firm.js";
import XEUtils from "xe-utils";

export default {
  name: "PoolTable",
  components: {},
  data() {
    return {
      coreTableLoading: false,
      tableHeight: "500px",
      filterName: "",
      poolList: [],
    };
  },
  computed: {
    list() {
      const filterName = XEUtils.toValueString(this.filterName)
        .trim()
        .toLowerCase();
      if (filterName) {
        const filterRE = new RegExp(filterName, "gi");
        const searchProps = ["name", "code", "pinyin"];
        const rest = this.poolList.filter((item) =>
          searchProps.some(
            (key) =>
              XEUtils.toValueString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
        return rest;
      }
      return this.poolList;
    },
  },
  mounted() {
    this.tableHeight = document.body.offsetHeight - 140 + "px";
    this.getFirmList();
    Bus.$on("refresFirmList", (e) => {
      this.getFirmList();
    });
  },
  methods: {
    getFirmList() {
      this.coreTableLoading = true;
      Firm.geFirmList()
        .then((res) => {
          this.poolList = res.data;
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.coreTableLoading = false;
        });
    },
    deleteFirm(id) {
      this.$layer.confirm("确定要删除该组合吗？", (layerid) => {
        this.showLoading("删除中，请稍候");
        Firm.deleteFirm(id)
          .then((res) => {
            this.showToast("已成功删除！", "success", 5000);
            Bus.$emit("refresFirmList", true);
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
        this.$layer.close(layerid);
      });
    },
    addFirm() {
      this.$layer.iframe({
        content: {
          content: FirmAdd,
          parent: this,
          data: { iframeData: {} },
        },
        area: ["600px", "200px"],
        title: "新建组合(带*为必填)",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    openFirm(id) {
      this.appStore.currentFirmDetail = {
        id: id,
      };
      this.$router.push({
        name: "组合详情",
        params: { id: id },
      });
    },
  },
};
</script>
