var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vxe-toolbar',{staticClass:"px-3",attrs:{"refresh":{ query: _vm.getFirmList }},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('vxe-input',{staticClass:"mr-1",attrs:{"type":"search","placeholder":"全文搜索"},model:{value:(_vm.filterName),callback:function ($$v) {_vm.filterName=$$v},expression:"filterName"}}),_c('vxe-button',{staticClass:"px-1 py-0 ml-2",attrs:{"status":"my-primary","size":"mini","content":"新建组合"},on:{"click":function($event){return _vm.addFirm()}}})]},proxy:true}])}),_c('vxe-table',{ref:"coreTable",attrs:{"id":"coreTable","row-id":"id","data":_vm.list,"border":false,"resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","align":"center","loading":_vm.coreTableLoading,"column-config":{ resizable: true },"height":_vm.tableHeight,"size":"small","stripe":true,"sort-config":{ trigger: 'cell', multiple: true },"scroll-y":{ mode: 'wheel', gt: 50, oSize: 30 },"empty-render":{ name: 'NotData' },"filter-config":{ showIcon: false },"export-config":{}}},[_c('vxe-column',{attrs:{"fixed":"left","min-width":"70","field":"code","title":"组合名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start",on:{"click":function($event){return _vm.openFirm(row.id)}}},[_c('a',{staticClass:"text-xs font-weight-normal text-typo",attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(row.name ? row.name : "-"))])])]}}])}),_c('vxe-column',{attrs:{"width":"140","field":"created_at","title":"创建日期","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.created_at)+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"140","field":"created_at","title":"更新日期","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.updated_at)+" ")])]}}])}),_c('vxe-column',{attrs:{"title":"操作","type":"seq","width":"120","resizable":false,"show-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('a',{staticClass:"text-xs font-weight-normal text-typo",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.deleteFirm(row.id)}}},[_vm._v("删除")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }